import { APIS_PATH } from "../../../Constant/ApiConstant";
import { get } from "../../../Utils/axiosInterceptor";
import { profileAction } from "../../Reducers/ProfileReducer/ProfileReducer";

export const getProfileData = () => async (dispatch) => {
  const response = await get(APIS_PATH?.GET_PROFILE);
  console.log(response?.data?.data, ", response?.data?.data");
  const data = JSON.parse(response?.data?.data?.moduleSelect);
  let moduleSelectData = {};
  data?.forEach((ele) => {
    moduleSelectData = { ...moduleSelectData, [ele.moduleName]: ele.selected };
  });
  const newProfileData = {
    ...response?.data?.data,
    moduleSelect: moduleSelectData,
  };
  console.log(moduleSelectData, "moduleSelectData");
  dispatch(profileAction?.profileDetails(newProfileData || {}));
  return response;
};
